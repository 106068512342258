<template>
    <div class="login">
        <div class="head">
            <el-tabs v-model="activeName" type="card" style="width:100%;">
                <el-tab-pane label="登录" name="1"></el-tab-pane>
                <el-tab-pane label="旧版登录" name="2"></el-tab-pane>
            </el-tabs>
            <!-- <div class="left">
                <a 
                v-if="!$store.state.platform.casLogin"
                :href="`${this.$config.homeUrl}#/bideva/login`" 
                target="_blank"
                >
                    专家登录
                </a>
            </div> -->

            <div 
            class="right"
            >
                <!-- <a 
                v-if="$store.state.platform.casLogin"
                href="https://ecs.jtsww.com/registerController!regStep1.action" 
                target="_blank"
                >
                    立即注册
                </a> -->

                <a 
                    href="/register"
                >立即注册</a>
            </div>
        </div>

        <div class="body">
            <template
            v-if="$store.state.platform.casLogin"
            >
                <cas-login 
                   @clickHandler="clickHandler"
                   @getMenuAndGo="getMenuAndGo"
                   :type="activeName"
                ></cas-login>
            </template>

            <!-- <div style="border: 4px dashed #E6A23C; margin-bottom: 20px;">
                开发用cas功能，勿操作
                <cas-login></cas-login>
            </div> -->

            <template
            v-else
            >
                <phone-login 
                    v-if="loginType === 'phone'" 
                    @getMenuAndGo="getMenuAndGo"
                ></phone-login>
                
                <account-login
                    v-if="loginType === 'pwd'" 
                    @clickHandler="clickHandler"
                    @getMenuAndGo="getMenuAndGo"
                ></account-login>

                <client-only>
                    <ca-login
                        v-if="loginType === 'ca'" 
                        @getMenuAndGo="getMenuAndGo"
                    ></ca-login>
                </client-only>
            </template>
        </div>

        <div 
        v-if="!$store.state.platform.casLogin"
        class="foot"
        >

            <div class="title">
                其他登录方式
            </div>

            <div class="context">
                <label
                    v-if="loginType !== 'phone'"
                    @click="loginType = 'phone'"
                >手机号登录</label>

                <label
                    v-if="loginType !== 'pwd'"
                    @click="loginType = 'pwd'"
                >用户名登录</label>

                <label
                    v-if="loginType !== 'ca'"
                    @click="loginType = 'ca'"
                >数字证书登录</label>
            </div>
        </div>

        <my-dialog
            :title="title"
            v-model="dialogVisible"
            width="520px"
            :showclose="true"
            @close="cancelDialog"
        >
            <forgot-form
                :title="title"
                v-if="dialogVisible && !showPwd"
                @openNext="openNext"
                @close="cancelDialog"
            ></forgot-form>

            <update-password
                v-if="dialogVisible && showPwd"
                @cancelDialog="cancelDialog"
                :data="accountInfo"
            ></update-password>
        </my-dialog>
    </div>
</template>

<script>
import ForgotForm from './forgot-form';
import UpdatePassword from './update-password';
import AccountLogin from './account-login.vue';
import PhoneLogin from './phone-login.vue';
import CaLogin from './ca-login.vue';
import CasLogin from './cas-login';

export default {
    components: {
        ForgotForm,
        UpdatePassword,
        AccountLogin,
        PhoneLogin,
        CaLogin,
        CasLogin,
    },
    data() {
        return {
            dialogVisible: false,
            title: '',
            showPwd: false,
            accountInfo: {},

            loginType: 'pwd',   // pwd/phone/ca
            activeName: '1'
        };
    },
    methods: {
        // 关闭弹框
        cancelDialog() {
            this.dialogVisible = false;
            this.showPwd = false;
        },
        // 进入密码验证
        openNext(data) {
            this.accountInfo = data;
            this.showPwd = true;
        },
        clickHandler(flag) {
            this.dialogVisible = true;

            if (flag) {
                this.title = '找回登录名';
            } else {
                this.title = '找回密码';
            }
        },
        getMenuAndGo() {
            this.$store.dispatch('userinfo/jumpToSys');
        },
    },
};
</script>

<style scoped lang="scss">
@import '@css/var.scss';

.login {
    .head {
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        position: relative;

        .left {
            flex: 1;
            font-size: 20px;
            color: #575d6c;
            font-weight: bold;

            a {
                color: $primaryColor;
                margin-left: .4em;
                font-size: 18px;
            }
        }

        .right {
            position: absolute;
            right:0px;
            top: 15px;
            a {
                color: $primaryColor;
            }
        }
    }

    .foot {
        text-align: center;
        margin-top: 30px;

        .title {
            color: #575d6c;
            position: relative;
            line-height: 1em;

            &:before,
            &:after {
                content: '';
                display: block;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                border-top: 2px dashed #e1e1e3;
                width: 30%;
            }

            &:before {
                left: 0;
            }

            &:after {
                right: 0;
            }
        }

        .context {
            margin-top: 20px;
            user-select: none;

            label {
                color: $primaryColor;
                cursor: pointer;

                & + label {
                    margin-left: 1em;
                }
            }
        }
    }
}

.btn-login {
    width: 100%;
}
</style>