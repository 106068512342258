<template>
    <div class="cas-con">
        <el-form
            ref="form"
            :model="form"
            autocomplete="off"
            label-width="0"
            size="small"
            v-if="type == 1"
        >
            <el-form-item
                prop="account"
                :rules="newRule('用户名', 'required')"
            >
                <el-input
                    v-model="form.account"
                    clearable
                    autocomplete="off"
                    :placeholder="`请输入用户名`"
                ></el-input>
            </el-form-item>

            <el-form-item
                prop="password"
                :rules="newRule('密码', 'required')"
            >
                <el-input
                    v-model="form.password"
                    clearable
                    autocomplete="off"
                    type="password"
                    :placeholder="`请输入密码`"
                    show-password
                >
                </el-input>
            </el-form-item>

            <el-form-item>
                <client-only>
                    <drag-verify
                        ref="verify"
                        :width="296"
                        text="拖动滑块登录"
                        style="user-select: none"
                        @passcallback="submitHandler"
                    ></drag-verify>
                </client-only>
            </el-form-item>
        </el-form>

        <div class="btn-log-sign-in"  v-else>
            <a 
            class="btn" 
            :href="userlogin()"
            >
                用户登录
            </a>
        </div>

        <div
            class="v-form-dom"
            id="v-form-dom"
            ref="vFormDom"
            style="width: 0px; height: 0px; overflow: hidden;"
        ></div>

        <div class="append">
            <label @click="$emit('clickHandler',true)"
            >
                忘记登录名
            </label>
            <label @click="$emit('clickHandler',false)"
            >
                忘记密码
            </label>
        </div>
    </div>
</template>

<script>
import DragVerify from '@components/sys/drag-verify';
import CryptoJS from 'crypto-js';
import $ from 'jquery';

export default {
    props:['type'],
    components: {
        DragVerify,
    },
    data() {
        return {
            form: {
                account: '',
                password: '',
                grantType: 'password',
            },
        };
    },
    methods: {
        userlogin(){
            return this.$config.loginUrl;
        },
        submitHandler() {
            var that = this,
                password = this.AESencrypt(this.sysEncode(this.form.password)),
                lt,
                execution;

         
            //     http://39.106.12.100:30172/cas/login
            // encodeURIComponent(`${window.location.origin}/cas/login`)
            const CB_URL = encodeURIComponent(`${window.location.origin}/cas/login`), // 这里过网关就会获取不到身份
                SERVICE_URL = `${this.$config.casUrl}/login?action=getlt&service=${CB_URL}`,
                ACTOIN_URL = `${this.$config.casUrl}/login?service=${CB_URL}`,
                LOGOUT_URL = `${this.$config.casUrl}/logout`;

                // 使用代理 ${this.$store.state.api.casServeUrl}
                // 如果代理不行 ${this.$config.casUrl}

            new Chain().link(next => {
                // 表单验证

                this.$refs.form.validate(valid => {
                    if (valid) {
                        next();
                    } else {
                        this.$refs.verify.reset();
                    }
                });
            })
            .link(next => {
                // 校验账号密码是否正确

                this.$ajax({
                    type: 'post',
                    url: `${this.$store.state.api.authUrl}/single/login`,
                    data: {
                        ...this.form,
                        password,
                    },
                    callback: () => {
                        next();
                    },
                    complete: () => {
                        setTimeout(() => {
                            this.$refs.verify.reset();
                        }, 1000);
                    },
                });

                // next();
            })
            .link(next => {
                $.ajax({
                    type: "GET",
                    async: false,
                    url: LOGOUT_URL,
                    dataType: "jsonp",
                    jsonp: "callback",
                    jsonpCallback: "success_jsonpCallback",
                    success: function(data) {
                        
                    },
                    error(e) {
                        console.log(e.responseText);
                    },
                    complete() {
                        next();
                    },
                });
            })
            .link(next => {
                $.ajax({
                    type: "GET",
                    async: false,
                    url: SERVICE_URL,
                    dataType: "jsonp",
                    jsonp: "callback",
                    jsonpCallback: "success_jsonpCallback",
                    success: function(data) {
                        lt = data.lt;
                        execution = data.execution;

                        next();
                    },
                    error(e) {
                        console.log(e.responseText);
                    },
                });

                // lt = 'LT-11486-jFW5qmnWJaicTHwcpQ7GXGo9FjqpMk';
                // execution = 'e2s1';
                // next();
            }).link(next => {
                
                let form = document.createElement('form'),
                    formItems = [
                        {
                            name: 'username',
                            value: that.form.account,
                        },
                        {
                            name: 'userCode',
                            value: that.form.account,
                        },
                        {
                            name: 'password',
                            value: that.form.password,
                        },
                        {
                            name: 'lt',
                            value: lt,
                        },
                        {
                            name: 'execution',
                            value: execution,
                        },
                        {
                            name: '_eventId',
                            value: 'submit',
                        },
                        // {
                        //     name: 'domain',
                        //     value: 'ecs.jtsww.com',
                        // },
                        {
                            name: 'type',
                            value: 'CAS',
                        },
                        {
                            name: 'login_at',
                            value: '',
                        },
                    ];

                form.action = ACTOIN_URL;
                form.method = 'post';

                formItems.forEach(item => {
                    let input = document.createElement('input');
                    input.name = item.name;
                    input.value = item.value;

                    form.append(input);
                });

                this.$refs.vFormDom.innerHTML = '';
                this.$refs.vFormDom.append(form);

                form.submit();
                
                
                /*
                var formData = new FormData();
                
                formItems.forEach(item => {
                    let {name, value} = item;

                    formData.append(name, value);
                });

                this.$post(ACTOIN_URL, formData, data => {
                    console.log(data);
                })

                */
            }).run();
        },
        casLoginRedirectCheck() {
            var casredirect = this.getQuery('casredirect'),
                name = this.getQuery('name');

            // 是cas重定向的
            if (casredirect && name) {
                new Chain().link(next => {
                    this.$post(`${this.$store.state.api.authUrl}/check`, {
                        name,
                    }, (data,res) => {
                        if(!res.success){
                            showMsgBox(res.msg, 'warning', (action) => {
                                if (action === 'confirm') {
                                    window.location.replace(
                                        `${this.$config.webUrl}`
                                    );
                                }
                            });
                        }else{
                            this.setToken(data.accessToken);
                        }

                        next();
                    });

                }).link(next => {
                    this.$store.dispatch('userinfo/jumpToSys');
                }).run();
            }
        },
    },
    mounted() {
        this.casLoginRedirectCheck();
    },
};
</script>
<style lang='scss' scoped>
@import '@css/var.scss';
.append {
    text-align: right;
    margin-top: 10px;
    color: #abafba;

    label {
        color: #abafba;
    }

    label {
        cursor: pointer;
    }

    label {
        cursor: pointer;
        &:hover {
            color: $primaryColor;
        }
    }
}

.btn-log-sign-in{
    padding: 2em 0 4em;
    text-align: center;

    .btn {
        display: inline-block;
        background-color: $primaryColor;
        color: white;
        line-height: 1em;
        font-size: 16px;
        padding: .8em 1.4em;
        border-radius: 4px;
        transition: all .3s;

        &:hover{
            background-color: $primaryColor9;
            box-shadow: 2px 2px 4px #666;
        }
    }

}

.cas-con{
    position: relative;

    &:before {
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        position: absolute;
        // background: #DCDFE6;
        top: -14px;
    }
}
</style>
