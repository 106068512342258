<template>
    <div>
        <my-form
            ref="form"
            v-model="form"
            autocomplete="off"
            label-width="0"
            @submit="submitHandler"
        >
            <el-form-item
                prop="account"
                :rules="newRule('用户名', 'required')"
            >
                <el-input
                    v-model="form.account"
                    clearable
                    autocomplete="off"
                    :placeholder="`请输入用户名`"
                ></el-input>
            </el-form-item>

            <el-form-item
                prop="password"
                :rules="newRule('密码', 'required')"
            >
                <el-input
                    v-model="form.password"
                    clearable
                    autocomplete="off"
                    type="password"
                    :placeholder="`请输入密码`"
                    show-password
                ></el-input>
            </el-form-item>

            <el-form-item style="user-select: none">
                <client-only>
                    <drag-verify
                        ref="verify"
                        :width="296"
                        text="拖动滑块登录"
                        @passcallback="submitHandler"
                    ></drag-verify>
                </client-only>
            </el-form-item>

            <template
                slot="btn"
                slot-scope="scope"
            >
                <el-button v-show="false">
                </el-button>
            </template>
        </my-form>

        <div class="append">
            <label @click="$emit('clickHandler',true)">忘记登录名</label> |
            <label @click="$emit('clickHandler',false)">忘记密码</label>
        </div>
    </div>
</template>

<script>
import DragVerify from '@components/sys/drag-verify';

export default {
    components: {
        DragVerify,
    },
    data() {
        return {
            form: {
                account: '',
                password: '',
                grantType: 'password',
            },
        };
    },
    mounted() {},
    methods: {
        submitHandler() {
            this.$refs.form.$refs.form.validate((valid) => {
                if (valid) {
                    var password = this.AESencrypt(this.sysEncode(this.form.password));

                    this.$store.dispatch('userinfo/smsloginHandler', {
                        data: {
                            ...this.form,
                            password,
                        },
                        success: () => {
                            // window.location.reload();
                            this.$emit('login');

                            this.model = false;

                            this.$store.dispatch(
                                'userinfo/loginCallbackDispatch'
                            );

                            // window.location.assign('/manage#/manage/user/dept/index');

                            this.$emit('getMenuAndGo');
                        },
                    });
                } else {
                    this.$refs.verify.reset();
                }
            });
        },
        dragPass() {
            // TODO: 滑动验证通过的规则；
            this.dragChecked = true;
        },
    },
};
</script>
<style lang='scss' scoped>
@import '@css/var.scss';
.append {
    text-align: right;
    margin-top: -10px;
    color: #abafba;
    label {
        cursor: pointer;
    }

    label {
        cursor: pointer;
        &:hover {
            color: $primaryColor;
        }
    }
}

</style>
