<template>
    <div>
        <my-form
            ref="form"
            v-model="form"
            autocomplete="off"
            label-width="0"
            @submit="submitHandler"
        >
            <el-form-item
                prop="registMobile"
                :rules="[...newRule('手机号','required','mobile')]"
                class="table-full-row"
            >
                <el-input
                    v-model="form.registMobile"
                    placeholder="请输入手机号"
                    clearable
                ></el-input>
            </el-form-item>

            <el-form-item
                required
                class="table-full-row"
            >
                <drag-yzm
                    :mobile="form.registMobile"
                    :drag-width="152"
                    :beforeSend="beforeSend"
                    text="滑动获取验证码"
                    :url="`${$store.state.api.authUrl}/send-login-verificationcode`"
                ></drag-yzm>
            </el-form-item>

            <el-form-item
                prop="sVerificationCode"
                :rules="newRule('手机验证码' ,'required')"
                class="table-full-row"
            >
                <div style="display:flex;">
                    <el-input
                        :disabled="disabledInput"
                        v-model="form.sVerificationCode"
                        placeholder="请输入验证码"
                        clearable
                    ></el-input>
                </div>
            </el-form-item>

            <template
                slot="btn"
                slot-scope="scope"
            >
                <el-button
                    type="primary"
                    class="btn-login"
                    @click="scope.submitHandler"
                >
                    登录
                </el-button>
            </template>
        </my-form>
    </div>
</template>

<script>
import DragYzm from '@components/sys/drag-yzm';
export default {
    components: {
        DragYzm,
    },
    data() {
        return {
            form: {
                registMobile: '',
                sVerificationCode: '',
            },
            disabledInput: true,
        };
    },
    mounted() {},
    methods: {
        submitHandler() {
            this.$store.dispatch('userinfo/phoneloginHandler', {
                data: {
                    ...this.form,
                },
                success: () => {
                    // window.location.reload();
                    this.$emit('login');
                    
                    this.model = false;

                    this.$store.dispatch(
                        'userinfo/loginCallbackDispatch'
                    );
                    // window.location.assign('/manage#/manage/user/dept/index');
                    this.$emit('getMenuAndGo');
                },
            });
        },
        beforeSend(send, deny) {
            this.disabledInput = false;
            send();
        },
    },
};
</script>
<style lang='scss' scoped>
.btn-login {
    width: 100%;
}
::v-deep {
    .drag_verify {
        .dv_handler {
            border: solid 1px #e4e9f0;
        }
        .dv_text {
            left: 4px;
            font-size: 12px;
        }
    }
}
</style>
