<template>
    <my-form
        ref="form"
        v-model="form"
        file
        @submit="submitHandler"
        :label-width="'95px'"
    >
        <el-form-item
            label="手机号"
            prop="registMobile"
            :rules="[...newRule('手机号','required','mobile')]"
            class="table-full-row"
        >
            <el-input
                v-model="form.registMobile"
                clearable
            ></el-input>
        </el-form-item>

        <el-form-item
            label="验证码"
            required
            class="table-full-row"
        >
            <drag-yzm
                :mobile="form.registMobile"
                :drag-width="200"
                :beforeSend="beforeSend"
                :url="`${this.$store.state.api.portalsiteUrl}/register/sendretrieveverificationcode`"
            ></drag-yzm>
        </el-form-item>

        <el-form-item
            label="手机验证码"
            prop="sRetrieveVerificationCode"
            :rules="newRule('手机验证码' ,'required')"
            class="table-full-row"
        >
            <div style="display:flex;">
                <el-input
                    :disabled="disabledInput"
                    v-model="form.sRetrieveVerificationCode"
                    clearable
                ></el-input>
            </div>
        </el-form-item>

        <template
            slot="btn"
            slot-scope="scope"
        >
            <el-button
                type="primary"
                @click="scope.submitHandler"
            >
                下一步
            </el-button>
        </template>

    </my-form>
</template>

<script>
import DragYzm from '@components/sys/drag-yzm';
export default {
    name: 'update-pwd',
    components: {
        DragYzm,
    },
    props: {
        title: {
            type: String,
        },
    },
    data() {
        return {
            form: {
                registMobile: '',
                sRetrieveVerificationCode: '',
            },
            btnDisabled: true,
            disabledInput: true,
        };
    },
    methods: {
        beforeSend(send, deny) {
            this.disabledInput = false;
            send();
        },
        submitHandler() {
            var form = { ...this.form };

            this.$post(
                `${this.$store.state.api.portalsiteUrl}/register/getretrieveaccount`,
                form,
                (data) => {
                    if (this.title === '找回密码') {
                        this.$emit('openNext', {
                            ...data,
                            sRetrieveVerificationCode:
                                this.form.sRetrieveVerificationCode,
                        });
                    } else {
                        if (JSON.stringify(data) !== '{}') {
                            showMsgBox(
                                `您的登录名是：${data.account}`,
                                'success',
                                (data) => {
                                    this.$emit('close');
                                }
                            );
                        } else {
                            showMsg('该手机号未注册！');
                        }
                    }
                }
            );
        },
    },
    mounted() {},
};
</script>
<style lang="scss" scoped>
::v-deep {
    .drag_verify {
        .dv_handler {
            border: solid 1px #e4e9f0;
        }
    }
}
</style>