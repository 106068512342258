<template>
    <my-form
        ref="form"
        table
        v-model="form"
        file
        @submit="submitHandler"
        :label-width="'120px'"
    >
        <el-form-item
            label="用户名"
            class="table-full-row"
        >
            {{form.account}}
        </el-form-item>

        <el-form-item
            label="新密码"
            prop="password"
            class="table-full-row"
            :rules="[...newRule('登录密码','required','min6'),{ validator: validateNewPwd, trigger: 'blur' }]"
        >
            <el-input
                v-model="form.password"
                clearable
                type="password"
                autocomplete="off"
                placeholder="输入不少于6位，格式为字母+数字"
                show-password
            ></el-input>
        </el-form-item>

        <el-form-item
            label="确认新密码"
            prop="confirmPwd"
            class="table-full-row"
            :rules="[...newRule('确认新密码','required'),{ validator: validateConfirmPwd, trigger: 'blur' },]"
        >
            <el-input
                v-model="form.confirmPwd"
                clearable
                autocomplete="off"
                type="password"
                show-password
            ></el-input>
        </el-form-item>
        <template
            slot="btn"
            slot-scope="scope"
        >
            <el-button
                type="primary"
                @click="scope.submitHandler"
            >
                保存
            </el-button>
            <el-button @click="cancelHandler">
                取消
            </el-button>
        </template>

    </my-form>
</template>

<script>
export default {
    name: 'update-pwd',
    props: {
        data: {
            type: Object,
        },
    },
    data() {
        return {
            form: {
                password: '',
                confirmPwd: '',
                account: '',
            },
        };
    },
    methods: {
        submitHandler() {
            let password = this.AESencrypt(this.sysEncode(this.form.password));
            this.$post(
                `${this.$store.state.api.portalsiteUrl}/register/updatepasswordbyregistmobile`,
                {
                    sPassword: password,
                    registMobile: this.data.phone,
                    sRetrieveVerificationCode:
                        this.data.sRetrieveVerificationCode,
                },
                () => {
                    showMsg('修改成功', 'success');
                    this.$emit('cancelDialog');
                }
            );
        },
        cancelHandler() {
            this.$emit('cancelDialog');
        },
        validateNewPwd(rule, value, callback) {
            var pattern = /^(?=.*[0-9])(?=.*[a-zA-Z])(.{6,})$/;

            if (value === '') {
                callback(new Error('请输入密码'));
            } else if (!pattern.test(value)) {
                callback(new Error('请输入不少于6位数字和字母组合'));
            } else {
                callback();
            }
        },
        validateConfirmPwd(rule, value, callback) {
            if (value === '') {
                callback(new Error('请再次输入密码'));
            } else if (value !== this.form.password) {
                callback(new Error('两次输入密码不一致!'));
            } else {
                callback();
            }
        },
    },
    mounted() {
        this.form.account = this.data.account;
    },
};
</script>