<template>
    <div class="drag-yzm">
        <client-only>
            <drag-verify
                :width="dragWidth"
                :height="32"
                ref="verify"
                :isPassing.sync="model"
                :text="text"
                success-text="获取成功"
                background="#e4e9f0"
                progress-bar-bg="#52C41A"
                completed-bg="#52C41A"
                text-size="14"
                :circle="false"
                handlerIcon="el-icon-d-arrow-right"
                successIcon="el-icon-circle-check"
                @passcallback="dragPass"
                textColor="#fff"
            ></drag-verify>
        </client-only>

        <btn-yzm
            ref="btn"
            class="btn"
            :mobile="mobile"
            :disabled="btnDisabled"
            :url="url"
        ></btn-yzm>
    </div>
</template>

<script>
import BtnYzm from './btn-yzm';
import DragVerify from './drag-verify-custom.vue';

export default {
    components: {
        BtnYzm,
        DragVerify,
    },
    props: {
        mobile: {
            type: String,
            default: '',
        },
        dragWidth: {
            type: Number,
            default: 323
        },
        beforeSend: {
            type: [Function, Boolean],
            default: false,
        },
        text: {
            type: String,
            default: '拖动滑块获取验证码',
        },
        value: {
            type: Boolean,
            default: false,
        },
        url: {
            type: String,
        },
    },
    data() {
        return {
            model: false,

            btnDisabled: true,
        };
    },
    computed: {
        valueModel: {
            get() {
                return this.value;
            },
            set(val) {
                if (!val) {
                    this.model = false;
                    this.$refs.verify.reset();
                }

                this.$emit('input', val);
            },
        },
    },
    methods: {
        dragPass() {
            if (!this.mobile) {
                showMsgBox('请填写手机号', 'error', () => {
                    this.valueModel = false;
                });

                return;
            }

            if (this.beforeSend) {
                this.beforeSend(() => {
                        this.btnDisabled = false;

                        this.$refs.btn.getYzm();

                        this.valueModel = true;
                    }, () => {
                        this.valueModel = false;
                    });
            } else {
                this.btnDisabled = false;

                this.$refs.btn.getYzm();

                this.valueModel = true;
            }
        },
    }
};
</script>

<style lang="scss" scoped>
.drag-yzm{
    display: flex;

    .btn{
        margin-left: 8px;
        min-width: 135px;
    }
}

::v-deep{
    .drag_verify .dv_text{
        color:#fff;
        -webkit-text-fill-color:#fff !important;
    }
}
</style>